import Axios from 'axios';

//const token =
//  'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYW16YTE0N0BnbWFpbC5jb20iLCJpYXQiOjE3MTIzOTIxMDcsImV4cCI6MTcxMjk5NjkwN30.AFFHgiqgpHdLfLgSUJuWLeWeserjSpUS7P92WtOlA0o';

const axiosInstance = Axios.create({
  // node base url
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

// axiosInstance.defaults.headers = {
//   Authorization: `Bearer ${token}`,
//   //auth token injection
// };

axiosInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    throw new Error(error.request.data.message);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    switch (error.response.status) {
      case 500:
        console.log('bad request');
        break;
      default:
        console.log('something went wrong');
    }
    console.log('error', error);
  },
);

export default axiosInstance;
