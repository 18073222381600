import React from 'react';
import Logo from '../../Assets/logo.png';
import Button from '../Common/Button';
import InstagramIcon from '@mui/icons-material/Instagram';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import './style.css';

const Footer = () => {
  return (
    <div className="footer_bg">
      <div className="overlay">
        <div className="footer_wrapper main_container py-[15px] px-[10px]">
          <div className="flex item-center justify-between mt-[20px]">
            <div className="footer_logo">
              <img src={Logo} alt="logo" />
            </div>
            <h3 className="text-[20px]">Follow us</h3>
            <div className="icons flex">
              <AudiotrackIcon className="mr-[10px]" />
              <InstagramIcon />
            </div>
            <Button
              className={'black_btn ml-[10px]'}
              content={'CONTACT US'}
              onClick={console.log('contact clicked')}
            />
          </div>
          <p className="p-[10px]">
            Made with love by WingBerry LLC. All Rights Reserved.&nbsp;
            <a className="terms_link" href="">
              Terms & Conditions
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
