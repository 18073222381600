import React, { useState, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import HeroSection from '../../HeroSection';
import { CreatorInfo } from './constant';
import WorkshopDetailCard from '../../Common/WorkshopDetailCard';
import Tabs from '../../Common/Tabs';
import CreatorInfoSection from '../../Common/CreatorInfoSection';
import InfoSection from '../../Common/InfoSection';
import './style.css';
import apiMethod from '../../../services/apiService';
import Util from '../../../Util/Util';
import Footer from '../../Footer/Footer';
import Modal from '../../Common/Modal';
import { ReactComponent as ChatIcon } from '../../../Assets/chat.svg';
import Button from '../../Common/Button';
import GetDialogBodyChatHTML from './util';
import Signup from '../../Forms/Signup/index';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import FreeProductIcon from '../../Common/FreeProduct';
import { current } from '@reduxjs/toolkit';

const CreatorProfile = () => {
  const [setLoader] = useOutletContext();
  const [creator, setCreator] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalHandler, setModalHandler] = useState('chat');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [email, setEmail] = useState(''); // State for email
  const [phone, setPhone] = useState(''); // State for phone
  const [firstName, setFirstName] = useState(''); // State for firstName

  let { id } = useParams();

  useEffect(() => {
    loadHandler();
  }, []);

  const loadHandler = async () => {
    const url = `/v1/user/creator/${id}`;
    try {
      setLoader(true);
      const data = await apiMethod.get(url);
      let profilePic = data.body.profilePic;
      let imageUrl = `https://api.buzzsocial.ai/api/v1/file/profile/${profilePic}`;
      const imageData = await Util.fetchBlob(imageUrl);
      const image = URL.createObjectURL(imageData);
      setBackgroundImage(image);
      const updatedData = await dataTransformer(data, image);
      setCreator(updatedData);
      setLoader(false);
      const baseUrl = process.env.REACT_APP_API_ENDPOINT;
      apiMethod
        .post(`${baseUrl}/v1/analytics/workshop`, {
          event: 'creator page hit',
          workshopId: id,
        })
        .catch(() => {});
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  const dataTransformer = async (data, image) => {
    const updatedCreatorInfo = CreatorInfo?.map((item) => ({
      key: item?.key,
      imageURL: item?.imageURL,
      data: data?.body?.[item?.key],
      label: item?.label,
    }));

    let defSocHandle = data?.body?.defaultSocialMediaHandle;
    let defSocPlatform = data?.body?.defaultSocialMediaPlatform;
    const socialMediaHandles = [{ username: defSocHandle, socialMediaPlatform: defSocPlatform }];
    const workshopsData = data?.body?.workshops;

    const categoriesOption = data?.body?.categories?.map((item) => item?.title);
    const helpYouWith = data?.body?.subCategories?.map((item) => {
      let imageId = item.icon;
      let subCategoryImage = `https://api.buzzsocial.ai/api/v1/file/subcategory/filename/${imageId}`;
      return {
        label: item?.title,
        imageURL: subCategoryImage,
      };
    });

    const workshopDetails = workshopsData?.map((item) => ({
      workshopId: item.id,
      title: item?.title,
      description: item?.webDescription,
      date: item?.date,
      time: item?.time,
      price: item?.pricePerSeat,
      imageURL: `https://api.buzzsocial.ai/api/v1/file/workshop/${item.coverPicture}`,
      creatorProfilePic: image,
      userName: data?.body?.defaultSocialMediaHandle,
      currency: item?.currency,
    }));

    return {
      CreaterInfo: {
        name: data?.body?.firstName,
        socialMediaHandler: [
          {
            platform: socialMediaHandles?.socialMediaPlatform,
            userName: data?.body?.defaultSocialMediaHandle,
            email: data?.body?.email,
            icon: 'https://apksharing.s3.us-east-2.amazonaws.com/206467789_10900022.png',
          },
        ],
        following: data?.body?.followingCount,
        followers: data?.body?.followerCount,
      },
      bio: data?.body?.bio,
      categoriesOption: categoriesOption,
      helpYouWith: helpYouWith,
      workshopDetails: workshopDetails,
      updatedCreatorInfo: updatedCreatorInfo,
      digitalProduct: data?.body?.digitalProduct,
    };
  };

  const payButtonHandler = () => {
    console.log("'pay to continue' button clicked");
    setModalHandler('signup');
  };

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  const clickHandler = () => {
    setOpenModal(true);
  };

  // Handle form submission for downloading a file
  const handleFormSubmit = async () => {
    const creatorId = id;

    if (!email || !phone) {
      alert('Both email and phone number must be provided.');
      return;
    }

    if (!creatorId) {
      alert('Creator ID is missing.');
      return;
    }

    setLoader(true);
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await axios.post(`${baseUrl}/v1/user/addLeads`, {
        creatorId,
        firstName,
        email,
        phone,
      });

      let fileUrl = response.data.body.fileUrl;

      if (!fileUrl) {
        alert('File URL not received from API.');
        return;
      }

      // // Fetch the file as a blob
      // const fileResponse = await axios({
      //   url: fileUrl,
      //   method: 'GET',
      //   responseType: 'blob', // Important
      // });

      // const filename = fileUrl.split('/').pop();
      // // Create a blob object URL for the file
      // const blob = new Blob([fileResponse.data]);
      // const blobUrl = URL.createObjectURL(blob);

      // // Create a link element to trigger the download
      // const link = document.createElement('a');
      // link.href = blobUrl;
      // link.download = filename;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      window.location.href = fileUrl;
    } catch (error) {
      console.error('Error fetching file URL:', error);
      alert('Failed to fetch the file URL.');
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="creator_page">
        <HeroSection
          creatorData={creator?.CreaterInfo}
          chatIcon={true}
          backgroundImg={backgroundImage}
        />
        {/* <Button
          content={<ChatIcon />}
          className={'multi_color_btn chat_btn'}
          onClick={clickHandler}
        /> */}
        <div className="Workshop_section py-[10px]">
          <div className="main_container">
            {false && <CreatorInfoSection data={creator?.updatedCreatorInfo} />}
            <h3 className="heading text-[24px] !important text-center text-slate-500 my-[20px] mt-14">
              Categories
            </h3>
            <Tabs option={creator?.categoriesOption} />
            <h3 className="heading my-[30px] text-center text-slate-500">Can help with</h3>
            <InfoSection data={creator?.helpYouWith} />
            <h3 className="heading text-center text-slate-500 my-[20px]">Bio</h3>
            <p className="mb-[40px] text-[14px] font-medium text-slate-800">{creator?.bio}</p>
            {creator?.digitalProduct && (
              <>
                <h3 className="heading text-slate-500 text-center my-[40px]">
                  {creator.digitalProduct?.heading}
                </h3>
                <div className="my-[60px] free_product_icon">
                  <FreeProductIcon
                    icon={creator?.digitalProduct?.iconUrl}
                    heading={creator?.digitalProduct?.heading}
                    subheading={creator?.digitalProduct?.subHeading}
                    buttonText={creator?.digitalProduct?.buttonText}
                    email={email} // Pass email state
                    phone={phone} // Pass phone state
                    firstName={firstName} // Pass phone state
                    setEmail={setEmail} // Pass setter for email
                    setPhone={setPhone}
                    setFirstName={setFirstName} // Pass setter for phone
                    onSubmit={handleFormSubmit}
                  />
                </div>
              </>
            )}
            <h3 className="heading text-center text-slate-500 my-[40px]">Events</h3>
            <div className="w-full">
              {creator?.workshopDetails?.map((item, index) => (
                <WorkshopDetailCard data={item} key={`tiles-${index}`} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {openModal && (
        <Modal
          className="workshop_dialog chat_modal_wrapper"
          id=""
          open={openModal}
          handleClose={modalCloseHandler}
          bodyClass="!border-0"
          showCloseButton={false}>
          {modalHandler === 'chat' ? (
            <GetDialogBodyChatHTML payButtonHandler={payButtonHandler} />
          ) : (
            <Signup />
          )}
        </Modal>
      )}
    </>
  );
};

export default CreatorProfile;
