import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HeroSection from '../../HeroSection';
import './style.css';
import {
  categoriesOptionDetails as categoryOptionMap,
  descriptionTiles as descriptionArray,
  languageMapper,
} from './util';
import InfoSection from '../../Common/InfoSection';
import Tabs from '../../Common/Tabs';
import ImageInfoCard from '../../Common/ImageInfoCard';
import DescriptionSlide from '../../Common/DescriptionSlide';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Signup from '../../Forms/Signup/index';
import apiMethod from '../../../services/apiService';
import Util from '../../../Util/Util';
import { useOutletContext } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import defaultProfileImage from '../../../Assets/Creator_Place_Holder.svg';
import defaultCoverImage from '../../../Assets/default_workshop_cover_pic.jpg';
import ReactGA from 'react-ga4';
const TRACKING_ID = 'G-PMB99CRRRE';

const Workshop = () => {
  const [setLoader] = useOutletContext();
  const [workShopData, setWorkshopData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState(''); // State for email
  const [phone, setPhone] = useState(''); // State for phone
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadHandler();
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: 'Workshop Page' });
  }, []);

  // useEffect(() => {
  //   GA4React.pageview(location.pathname);
  // }, [location.pathname]);

  let { id } = useParams();

  const loadHandler = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const affiliate = queryParams.get('affiliate'); // Get referral from query parameter

    const url = `/v1/workshop/${id}`;
    try {
      setLoader(true);
      const data = await apiMethod.get(url);
      const baseUrl = process.env.REACT_APP_API_ENDPOINT;
      let imageUrl = `${baseUrl}/v1/file/profile/${data?.body?.creator?.profilePic}`;
      const imageData = await Util.fetchBlob(imageUrl);
      const image = imageData ? URL.createObjectURL(imageData) : defaultProfileImage;
      let coverPicUrl = `${baseUrl}/v1/file/workshop/${data?.body?.coverPicture}`;
      const coverImageData = await Util.fetchBlob(coverPicUrl);
      const coverImage = imageData ? URL.createObjectURL(coverImageData) : defaultCoverImage;
      const updatedData = await dataTransformer(data, image, coverImage);
      setWorkshopData(updatedData);
      setLoader(false);
      const key = 'workshopId';
      localStorage.setItem(key, id.toString());
      // Store referral in localStorage if it exists
      if (affiliate) {
        localStorage.setItem('affiliate', affiliate);
      }
      console.log('reaching analytics code');
      apiMethod
        .post(`${baseUrl}/v1/analytics/workshop`, {
          event: 'workshop hit',
          workshopId: id,
        })
        .catch(() => {});
      console.log('reaching analytics code');
    } catch (error) {
      console.log('error', error);
      setLoader(false);
      navigate('/');
    }
  };

  const dataTransformer = async (data, image, coverImage) => {
    const categoriesOption = data?.body?.categories?.map((item) => {
      return item?.title;
    });
    const categoriesOptionDetails = categoryOptionMap.map((item) => {
      return {
        key: item?.key,
        imageURL: item?.imageURL,
        label: getInfoSectionLabels(item?.key, data),
      };
    });
    const creatorData = data?.body?.creator;
    console.log(data.body.details);
    //data.body.details = data?.body?.details.replace(/\\n/g, '<br>');
    const descriptionTiles = descriptionArray.map((item) => {
      return { description: data?.body[item?.key] || item?.description, heading: item?.heading };
    });
    return {
      categoriesOption,
      title: data?.body?.title,
      categoriesOptionDetails,
      description: data?.body?.webDescription,
      time: data?.body?.registrationClosingDate,
      creator: creatorData,
      price: data?.body?.pricePerSeat,
      webAudienceDescription: data?.body?.webAudienceDescription,
      descriptionTiles,
      coverPhoto: coverImage,
      workshopEssentials: data?.body?.details,
      date: data?.body?.date,
      profilePic: image,
      currency: data?.body?.currency,
    };
  };

  const clickHandler = () => {
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    apiMethod
      .post(`${baseUrl}/v1/analytics/workshop`, {
        event: 'workshop buy now clicked',
        workshopId: id,
      })
      .catch(() => {});
    console.log('opening modal as true');
    setOpenModal(true);
  };

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  const getInfoSectionLabels = (key, data) => {
    switch (key) {
      case 'language': {
        return languageMapper[data?.body[key]];
      }
      case 'time': {
        return `${Util?.formatDate(data?.body?.date)} - ${Util?.formatTime(data?.body?.time)} GST`;
      }
      default: {
        return data?.body[key];
      }
    }
  };

  return (
    <>
      {workShopData?.title && (
        <Helmet>
          <title> {workShopData?.title} </title>
          <meta name="og:image" content={workShopData?.coverPhoto} />
          <meta name="description" content={workShopData?.description} />
        </Helmet>
      )}
      <HeroSection
        heading="Buzz Event 🤝"
        backgroundImg={workShopData?.coverPhoto}
        //description={`Enrollment ends ${Util?.formatDate(workShopData?.time)} Claim your spot now. ⌛ !!`}
        completeDateTime={workShopData?.time}
      />
      <div className="newbies_section my-[40px]">
        <div className="main_container">
          <div className="heading_section flex justify-between items-center py-[10px]">
            <h3 className="text-[30px] font-bold">{workShopData?.title}</h3>
            <p className="text-[20px] text-[#E042C2] font-bold">{`${workShopData.currency} ${workShopData?.price?.toFixed(2)}`}</p>
          </div>
          <ImageInfoCard creator={workShopData?.creator} profilePic={workShopData?.profilePic} />
          <div className="Categories_wrapper pt-[10px] pb-[10px]">
            <h3 className="text-[30px] text-[#53018B] font-bold mt-20">Categories</h3>
            <Tabs option={workShopData?.categoriesOption} />
            <InfoSection data={workShopData?.categoriesOptionDetails} />
          </div>
        </div>
      </div>
      <div className="description_section mt-[20px] mb-[50px]">
        <div className="main_container">
          <h3 className="text-[30px] text-[#53018B] font-bold">Description</h3>
          <p
            className="text-[20px] text-[#3B3B3B] my-[15px]"
            dangerouslySetInnerHTML={{ __html: workShopData?.description }}
          />
          <div className="py-[0px]">
            {workShopData?.descriptionTiles?.map((item, index) => (
              <DescriptionSlide data={item} key={`tiles-${index}`} />
            ))}
          </div>
          <div className="flex justify-center">
            <Button
              className={'multi_color_btn w-3/8'}
              content={`Buy Ticket ${workShopData.currency} ${workShopData?.price?.toFixed(2)} -->`}
              onClick={clickHandler}
            />
          </div>
          <p style={{ fontSize: '0.8rem', color: '#555', marginTop: '10px', textAlign: 'center' }}>
            Secured with{' '}
            <a
              href="https://telr.com/about-telr/"
              style={{ color: '#0000FF', textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer">
              Telr
            </a>
          </p>
        </div>
      </div>
      <Footer />
      {openModal && (
        <Modal
          className="workshop_dialog"
          id=""
          open={open}
          handleClose={() => modalCloseHandler()}
          bodyClass="!border-0"
          showCloseButton={false}>
          {<Signup />}
        </Modal>
      )}
    </>
  );
};

export default Workshop;
