import React, { useEffect, useState } from 'react';
import { ReactComponent as ChatAnimationIcon } from '../../../Assets/chat_animation.svg';
import { ReactComponent as AlarmClockIcon } from '../../../Assets/alarm_clock_icon.svg';
import Button from '../../Common/Button';
import apiMethod from '../../../services/apiService';

const GetDialogBodyChatHTML = ({ payButtonHandler }) => {
  const [chatData, setChatData] = useState({});

  useEffect(() => {
    loadHandler();
  }, []);

  const loadHandler = async () => {
    console.log('in chat use effect');
    const url = `/v1/tajwez/creator/1/queuedetails`;
    try {
      const data = await apiMethod.get(url);
      console.log('API data is', data);
      const updatedData = await dataTransformer(data);
      setChatData(updatedData);
      console.log('updated data', updatedData);
    } catch (error) {
      console.log('error', error);
    }
  };

  const dataTransformer = async (data) => {
    console.log('data', data);
    return {
      numberOfPeoplePaying: data?.body?.numberOfPeoplePaying,
      tajwezId: data?.body?.tajwezId,
      waitTime: data?.body?.getEstimatedTime,
      eachSessionTime: data?.body?.eachSessionTime,
    };
  };

  const clickHandler = () => {
    payButtonHandler();
  };

  return (
    <div className="chat_modal flex justify-center items-center flex-col">
      <p className="text-[#53018B] text-[20px] text-center">Get Ready To Chat.</p>
      <p className="text-[#53018B] text-[20px] mb-[20px] text-center">Time To Grab Some Coffee</p>
      <ChatAnimationIcon className="chat_animation mb-[20px]" />
      <div className="time_circle">
        <p className="text-[#53018B] text-[22px]">
          <span className="text-[40px] font-semibold">{chatData?.waitTime}</span> Min
        </p>
        <p className="text-[#53018B] text-[16px]">Wait time</p>
      </div>
      <AlarmClockIcon />
      <p className="text-[12px] text-[#000000] mt-[5px] text-center">
        Each chat session is{' '}
        <span className="text-[#53018B]">{`${chatData?.eachSessionTime} minutes`}</span>
      </p>
      <p className="text-[12px] text-[#000000] mt-[5px] text-center">
        Number of people paying right now{' '}
        <span className="text-[#53018B]">{`${chatData?.numberOfPeoplePaying}`}</span>
      </p>
      <p className="text-[12px] text-[#000000] mb-[10px] text-center">
        You can always refresh this window to get the latest wait time.
      </p>
      <Button
        content={'Pay to Continue'}
        className={'multi_color_btn w-[80%] h-[45px] mb-[5px]'}
        onClick={clickHandler}
      />
      <p className="text-[14px] text-[#ED8FDB] mb-[10px] text-center">
        I am not interested to chat
      </p>
      <p className="text-[14px] text-[#53018B] w-[70%] text-center">
        Please complete payment to access the chat room
      </p>
    </div>
  );
};

export default GetDialogBodyChatHTML;
