import axiosInstance from '../config/interceptor';

const get = (url, queryParams = '') => {
  const res = axiosInstance.get(url, {
    params: queryParams,
    headers: {
      // 'Content-Type': 'application/json',
      // Accept: 'application/json',
    },
  });
  return res;
};

const post = (url, body, queryParams = '') => {
  return axiosInstance
    .post(url, body, {
      params: queryParams,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then((res) => res);
};

const put = (url, body, queryParams = '') => {
  return axiosInstance
    .put(url, body, {
      params: queryParams,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then((res) => res.data);
};

const apiMethod = { get, post, put };

export default apiMethod;
