import React, { useState, useEffect } from 'react';
import './style.css';

const CountdownTimer = ({ dateWithTime }) => {
  const targetDate = new Date(dateWithTime).getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const currentDate = new Date().getTime();
    const timeDifference = targetDate - currentDate;

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="timer_content py-[50px]">
      <div className="timer_content_item w-1/4">
        <p className="count">{timeRemaining.days}</p>
        <p className="text">Days</p>
      </div>
      <div className="timer_content_item w-1/4">
        <p className="count">{timeRemaining.hours}</p>
        <p className="text">Hours</p>
      </div>
      <div className="timer_content_item w-1/4">
        <p className="count">{timeRemaining.minutes}</p>
        <p className="text">Minutes</p>
      </div>
      <div className="timer_content_item w-1/4">
        <p className="count">{timeRemaining.seconds}</p>
        <p className="text">Seconds</p>
      </div>
    </div>
  );
};

export default CountdownTimer;
