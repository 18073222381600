import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import ImageInfoCard from '../ImageInfoCard';
import Button from '../Button';
import Util from '../../../Util/Util';

const WorkshopDetailCard = ({ data }) => {
  console.log('data card', data);
  const navigate = useNavigate();
  let id = data.workshopId;
  console.log(' workshop id is==>', id);
  return (
    <div className="workshop_card my-[60px]">
      <div className="flex mx-[20px]">
        <div className=" w-[100%] h-[100%] mr-[30px] flex justify-center items-center">
          <img src={data.imageURL} alt="person" className=" object-contain w-32 h-32" />
        </div>
        <div className="card_content flex flex-col justify-center item-center w-[100%]">
          <p className="text-[16px] font-bold text-slate-900">{data?.title}</p>
        </div>
      </div>
      <div className="flex mx-[14px] mt-5 leading-snug">
        <p
          className="text-14px text-slate-800"
          dangerouslySetInnerHTML={{ __html: data?.description }}></p>
      </div>
      <div className="workshop_details flex">
        <div className="w-3/4 image_card_wrapper">
          <ImageInfoCard userName={data?.userName} profilePic={data?.creatorProfilePic} />
        </div>
        <div className="pl-[10px] w-1/2 flex flex-col">
          <p className="text-[14px] font-bold text-[#0000008A]">Date & Time</p>
          <p className="text-[14px] text-[#0000008A]">{`${Util?.formatDate(data?.date)} - ${Util?.formatTime(data?.time)}`}</p>
        </div>
      </div>
      <div className="payment_info px-[30px] py-[10px] flex justify-between">
        <div className="payment_content">
          <p className="text-[14px] text-slate-700">Payment required</p>
          <p className="text-[#e25aca] text-[14px] font-semibold">
            {`${data?.currency} ${data?.price?.toFixed(2)}`}{' '}
          </p>
        </div>
        <Button
          content={'Buy Tickets'}
          className={'multi_color_btn w-auto'}
          onClick={() => {
            navigate(`/workshop/view/${id}`);
          }}
        />
      </div>
    </div>
  );
};

export default WorkshopDetailCard;
