import React, { useEffect } from 'react';
import Button from '../../Common/Button';
import { useOutletContext } from 'react-router-dom';
import { ReactComponent as AppleStore } from '../../../Assets/Apple-store.svg';
import { ReactComponent as PlayStore } from '../../../Assets/play-store.svg';
import './style.css';

const CommonPage = () => {
  const [setLoader] = useOutletContext();

  useEffect(() => {
    setLoader(false);
  }, []);

  const getImageJSX = (str) => {
    if (str === 'apple') {
      return (
        <div className="flex justify-center items-center common_page_button">
          <AppleStore />
          <div className="ml-[-10px]">
            <p className="font-semibold text-[#ffffff] text-[14px]">Download on the</p>
            <p className="font-semibold text-[#ffffff] text-[20px]">Apple Store</p>
          </div>
        </div>
      );
    }
    return (
      <div className="flex justify-center items-center common_page_button">
        <PlayStore />
        <div className="">
          <p className="font-semibold text-[#ffffff] text-[14px]">Get it on</p>
          <p className="font-semibold text-[#ffffff] text-[20px]">Play Store</p>
        </div>
      </div>
    );
  };

  return (
    <div className="h-screen bg-[#fff3fc91] common_page_wrapper">
      <div className="main_container common_page h-[100%] flex items-center justify-center w-[60%] flex-col">
        <h3 className="heading mb-[70px] text-[#53018B]">
          Download the app on the App Store for further instructions.
        </h3>
        <div className="flex items-center justify-evenly w-full btn_wrapper">
          <a href="https://apps.apple.com/in/app/buzz-social/id6480592460">
            <Button content={getImageJSX('apple')} className={'store_btn black_btn'} />
          </a>
          <Button content={getImageJSX('android')} className={'store_btn black_btn'} />
        </div>
      </div>
    </div>
  );
};

export default CommonPage;
