const Util = {
  fetchBlob: async (url) => {
    const response = await fetch(url);
    console.log('img response', response);
    if (response?.status === 424) {
      return false;
    }
    return response.blob();
  },

  formatDate: (inputDate) => {
    const date = new Date(inputDate);
    const options = { month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  },

  formatTime: (inputTime) => {
    const [hours, minutes] = inputTime.split(':').map(Number);
    let formattedHours = hours % 12 || 12;
    const period = hours < 12 ? 'am' : 'pm';
    return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  },
};

export default Util;
