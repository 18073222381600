import React from 'react';
import './style.css';

const InfoSection = (props) => {
  const { data } = props;

  return (
    <div className="infoCard_details my-[20px] py-[15px]">
      {data?.map((item, index) => (
        <div key={`icons-div-${index}`} className="text-center">
          <img
            src={item?.imageURL}
            alt="icons"
            className="mb-[10px] max-w-[70px] max-h-[70px] w-[100px]! h-[100px] rounded-full object-fit"
          />
          <p className="text-slate-600">{item?.label}</p>
        </div>
      ))}
    </div>
  );
};

export default InfoSection;
