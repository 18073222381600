import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

const ImageInfoCard = ({ creator, profilePic, userName = '' }) => {
  let id = creator?.id;
  console.log('id is==>', id);
  // console.log('creator', creator);
  const navigate = useNavigate();
  return (
    <div className="ImageInfoCard_details flex w-full">
      <div className="image w-[20%] mr-[5%] max-h-24 max-w-24 cursor-pointer">
        <img
          // src="https://buzzsocial.ai/wp-content/uploads/2024/01/Image-140-2.png"
          src={profilePic}
          alt="person"
          className="rounded-full"
          onClick={() => {
            navigate(`/creator/${id}`);
          }}
        />
      </div>
      <div className="text-[14px] font-bold text-[#0000008A] w-[75%] break-all">
        <p>Created by</p>
        <p className="font-normal">{creator?.defaultSocialMediaHandle || userName}</p>
      </div>
    </div>
  );
};

export default ImageInfoCard;
