import React from 'react';
import './style.css';

const Tabs = (props) => {
  const { option } = props;

  return (
    <div className="tabs flex justify-center p-[5px]">
      {option?.map((item, index) => (
        <div key={`option-${index}`} className={`option ${item}`}>
          <a href="#">{item}</a>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
