import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

function Root() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (window.location.pathname === '/') {
      setLoader(false);
    }
  }, []);

  return (
    <>
      {loader && (
        <div className="flex items-center justify-center w-full h-screen overflow-hidden">
          <BounceLoader
            color={'#f584d3'}
            loading={loader}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div className={loader ? 'hidden' : ''}>
        <Outlet context={[setLoader]}></Outlet>
      </div>
    </>
  );
}

export default Root;
