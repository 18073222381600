import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
//import { useNavigate } from 'react-router-dom';

const FreeProductIcon = ({
  icon,
  heading,
  subheading,
  buttonText,
  email,
  phone,
  firstName,
  setEmail,
  setPhone,
  setFirstName,
  onSubmit,
}) => {
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  return (
    <div className="max-w-full mx-auto p-7">
      <div className="flex items-center m-2">
        <img src={icon} alt="Icon" className="w-1/3 h-1/3 rounded-md" />
        <div className="w-2/3 pl-4">
          <p className="text-[15px] font-medium text-slate-700">{subheading}</p>
        </div>
      </div>
      {/* <div className="bg-beige p-2 rounded-md mb-4">
        <p className="text-black">{subheading}</p>
      </div> */}
      <form
        className="space-y-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}>
        <div className="relative">
          <input
            type="text"
            value={firstName}
            onChange={handleFirstNameChange}
            placeholder="Name"
            className="w-full px-3 py-2 rounded-md border border-gray-300 disabled:bg-gray-200"
          />
        </div>
        <div className="relative">
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
            className="w-full px-3 py-2 rounded-md border border-gray-300 disabled:bg-gray-200"
          />
        </div>
        <div className="relative">
          <input
            type="tel"
            value={phone}
            onChange={handlePhoneChange}
            placeholder="Phone: +91 9334121999"
            className="w-full px-3 py-2 rounded-md border border-gray-300 disabled:bg-gray-200"
          />
        </div>
        <Button content={buttonText} className={'multi_color_btn w-full '} type="submit" />
      </form>
    </div>
  );
};

FreeProductIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  setFirstName: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FreeProductIcon;
