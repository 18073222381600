import React from 'react';
import './style.css';

const DescriptionSlide = (props) => {
  const { data } = props;

  return (
    <div className="tiles">
      <h3 className="text-[20px] text-[#53018B] font-semibold mb-[20px]">{data?.heading}</h3>
      <p
        className="text-[20px] text-[#3B3B3B]"
        dangerouslySetInnerHTML={{ __html: data?.description }}></p>
    </div>
  );
};
export default DescriptionSlide;
