import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './Components/Root';
import ErrorComponent from './Components/ErrorComponent';
import Workshop from './Components/Pages/Workshop';
import CreatorProfile from './Components/Pages/Creator';
import CommonPage from './Components/Pages/CommonPage';
import './App.css';
//import { GA4React } from 'react-ga4';

//const trackingId = 'G-PMB99CRRRE';
//const ga4react = new GA4React(trackingId);
//GA4React.initialize(trackingId);
//export { GA4React };

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorComponent />,
    children: [
      {
        path: '',
        element: <ErrorComponent />,
      },
      {
        path: 'workshop/:id',
        element: <Workshop />,
      },
      {
        path: 'workshop/view/:id',
        element: <Workshop />,
      },
      {
        path: 'creator/:id',
        element: <CreatorProfile />,
      },
      {
        path: 'product/:id',
        element: <Workshop />,
      },
      {
        path: 'next-step',
        element: <CommonPage />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
