import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import CancelIcon from '@mui/icons-material/Cancel';

export default function Modal({
  handleClose,
  open,
  children,
  className,
  header = '',
  bodyClass = '',
  scroll = '',
}) {
  const useStyles = styled(() => ({
    backDrop: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0,0,30,0.4)',
    },
  }));
  const classes = useStyles();

  return (
    <Dialog
      className={`relative dialog-component-wrapper ${className}`}
      onClose={handleClose}
      aria-labelledby="customized-dialog"
      open={open}
      fullWidth
      maxWidth="xs"
      BackdropProps={{
        classes: {
          root: classes?.backDrop,
        },
      }}
      scroll={scroll}>
      <CancelIcon
        onClick={handleClose}
        className="absolute right-[-7px] bottom-6 cursor-pointer "
      />
      {header ? (
        <DialogTitle className="dialog-header relative !text-[16px]" id="alert-dialog-title">
          {header}
        </DialogTitle>
      ) : null}
      <DialogContent className={`dialog-body ${bodyClass}`} dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
}
