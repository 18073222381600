export const CreaterInfo = {
  name: 'Asima',
  socialMediaHandler: [
    {
      platform: 'Instagram',
      userName: '@ashima',
      email: 'ashima@gmail.com',
      icon: 'icon',
    },
  ],
  following: 277,
  followers: 250,
};

export const options = [
  {
    imageURL: 'https://buzzsocial.ai/wp-content/uploads/2024/01/CompositeLayer-3.png',
    label: '12',
  },
  {
    imageURL: 'https://buzzsocial.ai/wp-content/uploads/2024/01/CompositeLayer-4.png',
    label: 'Arabic',
  },
  {
    imageURL: 'https://buzzsocial.ai/wp-content/uploads/2024/01/CompositeLayer-6.png',
    label: '1 Jan - 7:00pm',
  },
  {
    imageURL: 'https://buzzsocial.ai/wp-content/uploads/2024/01/CompositeLayer-4.png',
    label: 'Arabic',
  },
  {
    imageURL: 'https://buzzsocial.ai/wp-content/uploads/2024/01/CompositeLayer-6.png',
    label: '1 Jan - 7:00pm',
  },
];

export const CreatorInfo = [
  {
    key: 'creatorContentLanguages',
    imageURL: 'https://buzzsocial.ai/wp-content/uploads/2024/01/CompositeLayer-3.png',
    label: 'Language',
  },
  {
    key: 'price',
    imageURL: 'https://buzzsocial.ai/wp-content/uploads/2024/01/CompositeLayer-4.png',
    label: 'Price/session',
  },
  {
    key: 'country',
    imageURL: 'https://buzzsocial.ai/wp-content/uploads/2024/01/CompositeLayer-6.png',
    label: 'Country',
  },
];
