import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Components/Common/Button';
import errorImg from '../Assets/404-error-img.png';

const ErrorComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen bg-[#fff3fc91] error_page_wrapper">
      <div className="main_container h-[100%] flex items-center justify-center flex-col">
        <img src={errorImg} alt="" />
        <h3 className="mt-[20px] text-[40px] font-bold text-[#53018a]">Oops!</h3>
        <p className="mb-[20px] text-[20px]">Sorry, The page you requested was not found</p>
        <Button
          content="Go to Home Page"
          className={'multi_color_btn'}
          onClick={() => {
            navigate(`/workshop/view/7`);
          }}
        />
      </div>
    </div>
  );
};

export default ErrorComponent;
